import * as React from "react"
import Layout from "../components/layout"
import {Link} from "gatsby";

export default () => (
    <Layout title={"Alle julekort"}>
        <div>
            <h2><Link to="/2020/">Julekort 2020</Link></h2>
            <h2><Link to="/2021/">Julekort 2021</Link></h2>
            <h2><Link to="/2022/">Julekort 2022</Link></h2>
        </div>
    </Layout>
)
