import React from "react"
import "./layout.css"
import logo from "../images/logo.png"

export default function Layout({title, logo, children}) {
    return (
        <div>
        <header id="header">
            <img src={logo} width="100px"/>
            <h1>{title}</h1>
            <ul class="main-menu">
                <li><a href="#content"></a></li>
            </ul>
        </header>

        <div id="container">
            <div class="inner">
                <div id="content">
                        {children}
                </div>
            </div>
        </div>
        <footer>
        </footer>
        </div>
    )
}
